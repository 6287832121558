import { useEffect } from "react"
import { clarity } from "react-microsoft-clarity"

const MicrosoftClarity = ({ id }: { id: string }) => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV !== "production") return
    clarity.init(id)
  }, [id])
  return <></>
}

export default MicrosoftClarity
