"use client"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { useEffect } from "react"

import DangerIcon from "@/assets/icons/circle-alert.svg"
import InfoIcon from "@/assets/icons/circle-info.svg"
import DeleteIcon from "@/assets/icons/circle-minus.svg"
import SuccessIcon from "@/assets/icons/circle-success.svg"
import { useSnackbarStore } from "@/store/snackbarStore"

import styles from "./Snackbar.module.scss"

export default function Snackbar() {
  const snackbarType = useSnackbarStore(state => state.type) // success, info, delete, danger
  const snackbarMessage = useSnackbarStore(state => state.message)
  const closeSnackbar = useSnackbarStore(state => state.closeSnackbar)
  const isOpen = useSnackbarStore(state => state.isOpen)

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null

    if (isOpen) {
      timerId = setTimeout(() => {
        closeSnackbar()
      }, 6000)
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [closeSnackbar, isOpen])

  const variant = snackbarType || "success"

  let variantStyle = ""

  switch (variant) {
    case "info":
      variantStyle = "bg-[#E7F2FB] border-[#A7D9FF]"
      break
    case "success":
      variantStyle = "bg-[#E7F5F0] border-[#97D5B7]"
      break
    case "delete":
      variantStyle = "bg-second border-[#97ACC2]"
      break
    case "danger":
      variantStyle = "bg-danger border-[#FFBFBF]"
      break
  }

  const icon = () => {
    const classText = "w-6 h-6 mr-2 shrink-0"

    switch (variant) {
      case "info":
        return <InfoIcon className={classText} />
      case "success":
        return <SuccessIcon className={classText} />
      case "delete":
        return <DeleteIcon className={classText} />
      case "danger":
        return <DangerIcon className={classText} />
    }
  }

  return (
    <div className="fixed top-0 z-[999] h-0 w-full">
      <div
        className={`${styles.basic} ${variantStyle} ${
          isOpen ? "opacity-100" : "!top-[-200px] opacity-0"
        } relative flex rounded-xl border-1 transition`}
      >
        {icon()}
        <div className="whitespace-pre-line break-all pl-1 pr-2 pt-[2px] text-sm">
          {snackbarMessage}
        </div>
        <XMarkIcon
          className={styles.close}
          onClick={() => {
            useSnackbarStore.setState({ isOpen: false })
          }}
        />
      </div>
    </div>
  )
}
