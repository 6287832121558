/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */

class ChannelTalkClient {
  constructor() {
    this.loadScript()
  }

  private loadScript() {
    const windowReference = window as any
    if (windowReference.ChannelIO) {
      return
    }
    const channelIO = function () {
      channelIO.c(arguments)
    }
    channelIO.q = []
    channelIO.c = function (args: any) {
      channelIO.q.push(args)
    }
    windowReference.ChannelIO = channelIO
    function loadChannelIO() {
      if (windowReference.ChannelIOInitialized) {
        return
      }
      windowReference.ChannelIOInitialized = true
      const newScript = document.createElement("script")
      newScript.type = "text/javascript"
      newScript.async = true
      newScript.src = "https://cdn.channel.io/plugin/ch-plugin-web.js"
      newScript.charset = "UTF-8"
      const existScript = document.getElementsByTagName("script")[0]
      existScript.parentNode?.insertBefore(newScript, existScript)
    }

    if (document.readyState === "complete") {
      loadChannelIO()
    } else if (window.addEventListener) {
      // 여기에서 변경
      window.addEventListener("load", loadChannelIO, false)
    }
  }

  boot(settings: { pluginKey: string; hideChannelButtonOnBoot: boolean }) {
    ;(window as any).ChannelIO("boot", settings)
  }

  shutdown() {
    ;(window as any).ChannelIO("shutdown")
  }
}

export default ChannelTalkClient
