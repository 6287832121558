import { create } from "zustand"
import { persist } from "zustand/middleware"

interface ShowDataState {
  isShowData: boolean
  toggleShowData: () => void
}

interface BreakpointState {
  breakpoint: string
  isMobile: boolean
  setBreakpoint: (breakpoint: string) => void
}

interface LayoutState {
  isLeftOpen: boolean
  isCardChanged: boolean
  openGroup: string[]
  toggleLeft: () => void
  openLeft: () => void
  closeLeft: () => void
  setOpenGroup: (group: string[]) => void
  setCardChanged: (isCardChanged: boolean) => void
}

interface PageSizeState {
  pageSize: number
  setPageSize: (pageSize: number) => void
}

export const useBreakpointStore = create<BreakpointState>(set => ({
  isMobile: false,
  breakpoint: "sm",
  setBreakpoint: breakpoint =>
    set({ breakpoint, isMobile: breakpoint === "sm" || breakpoint === "xs" }),
}))

export const useLayoutStore = create<LayoutState>(set => ({
  isLeftOpen: true,
  isCardChanged: false,
  openGroup: ["지급요청 관리"],
  toggleLeft: () => set(state => ({ isLeftOpen: !state.isLeftOpen })),
  openLeft: () => set({ isLeftOpen: true }),
  closeLeft: () => set({ isLeftOpen: false }),
  setCardChanged: (isCardChanged: boolean) => set({ isCardChanged }),
  setOpenGroup: (group: string[]) => set({ openGroup: group }),
}))

export const usePageSizeStore = create<PageSizeState>()(
  persist(
    set => ({
      pageSize: 10,
      setPageSize: pageSize => set({ pageSize }),
    }),
    {
      name: "page-size", // persist key
    },
  ),
)

export const useShowDataStore = create<ShowDataState>()(
  persist(
    set => ({
      isShowData: false,
      toggleShowData: () => set(state => ({ isShowData: !state.isShowData })),
    }),
    {
      name: "show-dev-data", // persist key
    },
  ),
)
