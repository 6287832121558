import mixpanel, { Persistence } from "mixpanel-browser"
import { useEffect } from "react"

const MixpanelProvider = ({ token }: { token?: string }) => {
  useEffect(() => {
    if (!token) {
      console.error("Mixpanel token is not provided")
      return
    }
    const config = {
      debug: process.env.NEXT_PUBLIC_MIXPANEL_DEBUG === "true",
      track_pageview: false,
      cross_subdomain_cookie: true,
      persistence: "localStorage" as Persistence,
    }
    mixpanel.init(token, config)
  }, [token])
  return <></>
}

export default MixpanelProvider
