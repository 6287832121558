import mixpanel, { Dict } from "mixpanel-browser"

import { ProfileModel, ProfileWorkspaceModel } from "@/features/profiles"

const MixpanelIdentify = (
  profile: ProfileModel,
  { workspaceId }: { workspaceId: number },
  workspaces: ProfileWorkspaceModel[],
) => {
  mixpanel.identify(profile.account.id.toString())
  mixpanel.set_group(
    "workspace",
    workspaces.map(workspace => workspace.id),
  )
  workspaces.forEach(workspace => {
    mixpanel.get_group("workspace", workspace.id.toString()).set({
      id: workspace.id,
      $name: workspace.organizationName,
      organizationName: workspace.organizationName,
      representativeName: workspace.representativeName,
      businessRegistrationNumber: workspace.businessRegistrationNumber,
      taxRegistrationId: workspace.taxRegistrationId,
      address: workspace.address,
      businessType: workspace.businessType,
      businessItem: workspace.businessItem,
      staff: {
        id: workspace.staff.id,
        email: workspace.staff.email,
        displayName: workspace.staff.displayName,
        telephone: workspace.staff.telephone,
      },
    })
  })
  mixpanel.people.set({
    $email: profile.account.email,
    $name: profile.account.displayName,
  })
  MixPanelTrack("login_success", { workspaceId })
}

const MixPanelReset = ({ workspaceId }: { workspaceId: number }) => {
  MixPanelTrack("clicked__logout__left_side_bar", { workspaceId })
  mixpanel.reset()
}

const MixPanelTrack = (
  event: string,
  { workspaceId }: { workspaceId: number },
  properties?: Dict,
) => {
  mixpanel.track(event, { ...properties, workspace: workspaceId })
}

const MixPanelTrackPageView = (
  { workspaceId }: { workspaceId: number },
  properties?: Dict,
) => {
  mixpanel.track_pageview({ ...properties, workspace: workspaceId })
}

export const BoltaEvent = {
  identify: MixpanelIdentify,
  logout: MixPanelReset,
  track: MixPanelTrack,
  trackPageView: MixPanelTrackPageView,
}
