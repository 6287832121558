"use client"
import { useEffect } from "react"

import ChannelTalkClient from "./ChannelTalkClient"

const ChannelTalk = () => {
  useEffect(() => {
    const channelTalkClient = new ChannelTalkClient()
    channelTalkClient.boot({
      pluginKey: "3448f494-1d6a-4a4b-91db-6537d1f494ed",
      hideChannelButtonOnBoot: true,
    })

    return () => {
      channelTalkClient.shutdown()
    }
  }, [])

  return <></>
}

export default ChannelTalk
